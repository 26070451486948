<template>
  <div>
    <form class="mb-3" @submit.prevent="registerAccount">
      <p class="title">會員註冊</p>
      <!-- 電子信箱 -->
      <div class="form-floating mb-3">
        <input
          type="email"
          class="form-control"
          id="R_Account"
          placeholder="輸入電子信箱"
          v-model="registerData.account"
        />
        <label for="R_Account">輸入電子信箱</label>
      </div>
      <!-- 密碼 -->
      <div class="form-floating mb-3">
        <input
          type="password"
          class="form-control"
          id="R_Password"
          placeholder="輸入密碼(最高40個英文加數字)"
          maxlength="40"
          v-model="registerData.password"
        />
        <label for="R_Password">輸入密碼(最高40個英文加數字)</label>
      </div>
      <!-- 再次密碼 -->
      <div class="form-floating mb-3">
        <input
          type="password"
          class="form-control"
          id="R_PasswordAgain"
          placeholder="再次輸入密碼(最高40個英文加數字)"
          maxlength="40"
          v-model="registerData.confirmPassword"
        />
        <label for="R_PasswordAgain">再次輸入密碼(最高40個英文加數字)</label>
      </div>
      <!-- 使用者名稱 -->
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="R_UserName"
          placeholder="使用者名稱(15字)"
          maxlength="15"
          v-model="registerData.name"
        />
        <label for="R_UserName">使用者名稱(15字)</label>
      </div>
      <button class="form-btn" type="submit">登入/註冊</button>
    </form>
    <router-link
      class="d-block text-end"
      :to="identity === 'seller' ? '/sellerLogin' : '/customerLogin'"
      >返回登入頁面 >></router-link
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      registerData: {
        account: '',
        password: '',
        confirmPassword: '',
        name: '',
      },
      identity: 'customer'
    }
  },
  created() {
    // 初始化
    this.initialization()
  },
  methods: {
    // 初始化
    initialization() {
      this.registerData = {
        account: '',
        password: '',
        confirmPassword: '',
        name: '',
      }
      this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
    },
    // 註冊帳號
    registerAccount() {
      const vm = this
      const registerAccountApi = `${process.env.VUE_APP_API}/user/createAccount`
      const data = new FormData()
      data.append('account', this.registerData.account)
      if (this.registerData.password) {
        data.append('password', this.registerData.password)
      }
      if (this.registerData.confirmPassword) {
        data.append('confirmPassword', this.registerData.confirmPassword)
      }
      data.append('name', this.registerData.name)
      this.$methods.switchLoading('show')
      $.ajax({
        type: 'post',
        async: true,
        url: registerAccountApi,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const token = res.data
            vm.$methods.setCookie('serverToken', token)
            vm.$router.push(vm.$methods.go('normal'))
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          if (err.responseJSON.message === 'password不能為空') {
            vm.SweetAlert('other', '密碼不能為空')
          } else if (err.responseJSON.message === 'confirmPassword不能為空') {
            vm.SweetAlert('other', '確認密碼不能為空')
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
          vm.$methods.switchLoading('hide')
        }
      })
    },
  },
}
</script>